import { addressAboyeur } from "@app/domains/address/events";
import {
  usePersistedAddressWithChain,
  usePersistedAddressWithMerchant,
  usePersistedDeliveryMethod,
} from "@app/domains/address/hooks";
import {
  AutocompleteAddress,
  CustomerAddress,
} from "@app/domains/address/models";
import { ChainResponse } from "@app/domains/chain/models";
import { DeliveryMethod, MerchantResponse } from "@app/domains/merchant/models";
import { createContext, useContext, useMemo, useState } from "react";

type AddressType = "MERCHANT" | "CHAIN";

type AddressSettings = {
  defaultAutocompleteAddress?: AutocompleteAddress;
};

type AddressConfig = {
  isOpen: boolean;
  type: AddressType;
  onClose?: () => void;
  settings?: AddressSettings;
};

export type AddressContextValue = {
  deliveryMethod: DeliveryMethod | undefined;
  setDeliveryMethod: (deliveryMethod: DeliveryMethod) => void;
  getAddress: (type: AddressType) => CustomerAddress | undefined;
  setAddress: (type: AddressType, address: CustomerAddress) => void;
  addressConfig: AddressConfig;
  openAddress: (
    type: AddressType,
    settings?: AddressSettings,
    onClose?: () => void,
  ) => void;
  closeAddress: () => void;
};

const AddressContext = createContext<AddressContextValue | undefined>(
  undefined,
);

export type AddressProviderProps = {
  merchantResponse?: MerchantResponse;
  chainResponse?: ChainResponse;
};

const INITIAL_ADDRESS_CONFIG: AddressConfig = {
  isOpen: false,
  type: "MERCHANT",
};

export const AddressProvider: React.FC<AddressProviderProps> = ({
  merchantResponse,
  chainResponse,
  children,
}) => {
  const [persistedDeliveryMethod, setPersistedDeliveryMethod] =
    usePersistedDeliveryMethod(merchantResponse);

  const [persistedChainAddress, setPersistedChainAddress] =
    usePersistedAddressWithChain(chainResponse);
  const [persistedMerchantAddress, setPersistedMerchantAddress] =
    usePersistedAddressWithMerchant(merchantResponse);

  const [addressConfig, setAddressConfig] = useState(INITIAL_ADDRESS_CONFIG);

  function handleGetAddress(type: AddressType) {
    if (type === "CHAIN") return persistedChainAddress;

    return persistedMerchantAddress;
  }

  function handleSetAddress(type: AddressType, address: CustomerAddress) {
    if (type === "CHAIN") return setPersistedChainAddress(address);

    return setPersistedMerchantAddress(address);
  }

  function handleOpenAddress(
    type: AddressType,
    settings?: AddressSettings,
    onClose?: () => void,
  ) {
    setAddressConfig({
      isOpen: true,
      type,
      onClose,
      settings,
    });
    addressAboyeur.events.details.open();
  }

  function handleCloseAddress() {
    setAddressConfig(INITIAL_ADDRESS_CONFIG);
    addressAboyeur.events.details.close();
  }

  const contextValue: AddressContextValue = useMemo(
    () => ({
      deliveryMethod: persistedDeliveryMethod,
      setDeliveryMethod: setPersistedDeliveryMethod,
      addressConfig,
      getAddress: handleGetAddress,
      setAddress: handleSetAddress,
      openAddress: handleOpenAddress,
      closeAddress: handleCloseAddress,
    }),
    [
      persistedDeliveryMethod,
      setPersistedDeliveryMethod,
      addressConfig,
      handleGetAddress,
      handleSetAddress,
      handleOpenAddress,
      handleCloseAddress,
    ],
  );

  return (
    <AddressContext.Provider value={contextValue}>
      {children}
    </AddressContext.Provider>
  );
};

AddressProvider.displayName = "AddressProvider";

export function useAddress(): AddressContextValue {
  const context = useContext(AddressContext);

  if (typeof context === "undefined") {
    throw new Error(
      `'useAddress()' must be used within a '${AddressProvider.displayName}'`,
    );
  }

  return context;
}
