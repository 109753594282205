import { Customer } from "@app/domains/authentication/models";
import {
  getCustomerLocalStorage,
  isRefreshTokenMissing,
  removeCustomerLocalStorage,
  setCustomerLocalStorage,
} from "@app/domains/authentication/utils";
import { useState } from "react";

export type SetCustomerType = (customer: Customer) => void;
export type RemoveCustomerType = () => void;

type usePersistedCustomerType = {
  customer: Customer | undefined;
  setCustomer: SetCustomerType;
  removeCustomer: RemoveCustomerType;
};

export function usePersistedCustomer(): usePersistedCustomerType {
  const localCustomer = getCustomerLocalStorage();

  const [persistedCustomer, setPersistedCustomer] = useState<
    Customer | undefined
  >(() => {
    if (!localCustomer) {
      return;
    }

    if (isRefreshTokenMissing()) {
      removeCustomerLocalStorage();
      return;
    }

    return Customer.fromJSON(localCustomer);
  });

  function handleSetPersistedCustomer(customer?: Customer) {
    if (!customer) {
      removeCustomerLocalStorage();
      setPersistedCustomer(undefined);
      return;
    }

    const customerJSON = customer.toJSON();
    setPersistedCustomer(customer);
    setCustomerLocalStorage(customerJSON);
  }

  function handleRemovePersistedCustomer() {
    setPersistedCustomer(undefined);
  }

  return {
    customer: persistedCustomer,
    setCustomer: handleSetPersistedCustomer,
    removeCustomer: handleRemovePersistedCustomer,
  };
}
