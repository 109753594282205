import { Order as CheckoutOrder } from "@app/domains/checkout/models";

import { GoogleAnalytics } from "./google-analytics";

export class GoogleAds extends GoogleAnalytics {
  doConversion(
    order: CheckoutOrder,
    transactionId: string,
    googleAdsID: string,
  ) {
    this.gtagPush("conversion", {
      send_to: googleAdsID,
      transaction_id: transactionId,
      currency: order.totalOrder.currency,
      value: order.totalOrder.getValue(),
    });
  }
}
