import {
  CustomerAddress,
  CustomerAddressJSON,
} from "@app/domains/address/models";
import { ChainResponse } from "@app/domains/chain/models";
import { useMemo, useState } from "react";
import createPersistedState from "use-persisted-state";

const CURRENT_ADDRESS_KEY = "current_address";

export function usePersistedAddressWithChain(
  chain?: ChainResponse,
): [CustomerAddress | undefined, (customerAddress: CustomerAddress) => void] {
  const useLocalCurrentAddress = useMemo(
    () =>
      createPersistedState<CustomerAddressJSON>(
        `${chain?.slug}.${CURRENT_ADDRESS_KEY}`,
      ),
    [chain],
  );

  const [localCurrentAddress, setLocalCurrentAddress] =
    useLocalCurrentAddress();
  const [persistedAddress, setPersistedAddress] = useState<
    CustomerAddress | undefined
  >(() => {
    if (!localCurrentAddress) {
      return;
    }

    return CustomerAddress.fromJSON(localCurrentAddress);
  });

  function handleSetPersistedAddress(customerAddress: CustomerAddress) {
    const customerAddressJSON = customerAddress.toJSON();
    setLocalCurrentAddress(customerAddressJSON);
    setPersistedAddress(customerAddress);
  }

  return [persistedAddress, handleSetPersistedAddress];
}
