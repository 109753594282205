import { Item as CatalogItem } from "@app/domains/catalog/models";
import { Order as CheckoutOrder } from "@app/domains/checkout/models";

import { EventName, GTAGDataObject, GTAGInstance, Product } from "./types";

declare global {
  interface Window {
    gtag?: GTAGInstance;
  }
}

export class GoogleAnalytics {
  static formatProduct(item: CatalogItem, quantity: number): Product {
    return {
      name: item.description,
      id: item.id,
      category: item.category,
      price: item.unitPrice.getValue(),
      currency: item.unitPrice.currency,
      item_id: item.id,
      item_name: item.description,
      item_category: item.category,
      quantity,
    };
  }

  gtagPush(label: EventName, data: GTAGDataObject) {
    if (!window.gtag) {
      return;
    }
    window.gtag("event", label, data);
  }

  click(item: CatalogItem) {
    this.gtagPush("select_content", {
      content_type: "product",
      items: [GoogleAnalytics.formatProduct(item, 1)],
    });
  }
  viewDetail(item: CatalogItem) {
    this.gtagPush("view_item", {
      items: [GoogleAnalytics.formatProduct(item, 1)],
    });
  }
  addToCart(item: CatalogItem) {
    this.gtagPush("add_to_cart", {
      items: [GoogleAnalytics.formatProduct(item, 1)],
    });
  }
  updateItem(item: CatalogItem) {
    this.gtagPush("add_to_cart", {
      items: [GoogleAnalytics.formatProduct(item, 1)],
    });
  }
  removeFromCart(item: CatalogItem) {
    this.gtagPush("remove_from_cart", {
      items: [GoogleAnalytics.formatProduct(item, 1)],
    });
  }
  beginCheckout(order: CheckoutOrder) {
    this.gtagPush("begin_checkout", {
      items: order.itemsList.map((item) =>
        GoogleAnalytics.formatProduct(item.catalogItem, item.quantity),
      ),
      coupon: order.campaignCode,
    });
  }
  addCoupon(order: CheckoutOrder) {
    this.gtagPush("checkout_progress", {
      items: order.itemsList.map((item) =>
        GoogleAnalytics.formatProduct(item.catalogItem, item.quantity),
      ),
      coupon: order.campaignCode,
    });
  }
  checkoutStep(checkout_step: number, checkout_option: string) {
    this.gtagPush("set_checkout_option", {
      checkout_step,
      checkout_option,
    });
  }
  shippingInfo() {
    this.gtagPush("add_shipping_info", {
      currency: "BRL",
      value: 0,
      items: [],
    });
  }
  search(query: string) {
    this.gtagPush("search", {
      search_term: query,
    });
  }
  doCheckout(order: CheckoutOrder, transaction_id: string) {
    this.gtagPush("purchase", {
      transaction_id,
      currency: order.totalOrder.currency,
      value: order.totalOrder.getValue(),
      shipping: order.deliveryFee?.getValue(),
      coupon: order.campaignCode,
      items: order.itemsList.map((item) =>
        GoogleAnalytics.formatProduct(item.catalogItem, item.quantity),
      ),
    });
  }
}
