import { Item as CatalogItem } from "@app/domains/catalog/models";
import { Order as CheckoutOrder } from "@app/domains/checkout/models";

declare global {
  interface Window {
    fbq?: facebook.Pixel.Event;
  }
}

export class FacebookPixel {
  static formatProduct(item: CatalogItem) {
    return {
      content_name: item.description,
      content_ids: item.id,
      content_type: "product",
      value: item.unitPrice.getValue().toString(),
      content_category: item.category,
      currency: "BRL",
    };
  }

  fbqTrack(event: string, args?: any) {
    if (!window.fbq) {
      return;
    }

    window.fbq("track", event, args);
  }

  search(term: string) {
    this.fbqTrack("Search", {
      search_string: term,
    });
  }
  viewDetail(item: CatalogItem) {
    this.fbqTrack("ViewContent", FacebookPixel.formatProduct(item));
  }
  addToCart(item: CatalogItem) {
    this.fbqTrack("AddToCart", FacebookPixel.formatProduct(item));
  }
  updateItem(item: CatalogItem) {
    this.fbqTrack("EditItem", FacebookPixel.formatProduct(item));
  }
  beginCheckout(order: CheckoutOrder) {
    this.fbqTrack("InitiateCheckout", {
      num_items: order.itemsList.length,
      value: order.totalOrder.getValue().toString(),
      currency: "BRL",
    });
  }
  checkoutStep(step: number, _: string) {
    if (step === 1) {
      this.fbqTrack("AddPaymentInfo");
    }
  }
  doCheckout(order: CheckoutOrder, _: string) {
    this.fbqTrack("Purchase", {
      currency: "BRL",
      value: order.totalOrder.getValue().toString(),
    });
  }
}
