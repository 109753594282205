import { removePhoneMask } from "@app/domains/shared/string-utils";
import * as EmailValidator from "email-validator";
import * as yup from "yup";

export const NAME_ERROR = "Digite seu nome e sobrenome para continuar";
export const EMAIL_ERROR = "Digite um email válido para continuar";
export const PHONE_ERROR = "Digite um número de celular válido para continuar";
export const CODE_ERROR = "Digite o código de 6 dígitos para continuar";

export const validationEmail = yup
  .string()
  .required(EMAIL_ERROR)
  .min(5, EMAIL_ERROR)
  .max(50, EMAIL_ERROR)
  .trim()
  .test("is-valid", EMAIL_ERROR, (value) => {
    if (!value || !EmailValidator.validate(value)) {
      return false;
    }
    return true;
  });

export const validationOTP = yup.string().length(6).required(CODE_ERROR);

export const validationName = yup
  .string()
  .required(NAME_ERROR)
  .min(5, NAME_ERROR)
  .max(50, NAME_ERROR)
  .trim()
  .test("is-valid", NAME_ERROR, (value) => {
    if (!value || value.trim().indexOf(" ") === -1) {
      return false;
    }
    return true;
  });

export const validationPhone = yup
  .string()
  .required(PHONE_ERROR)
  .test("is-valid", PHONE_ERROR, (value) => {
    const phone = removePhoneMask(value || "");

    if (!phone || phone[2] !== "9" || phone.length !== 11) return false;

    return true;
  });
